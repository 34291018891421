import API from "../../../../global/api";
import { isEmpty, isNull, sum } from "lodash";
import logo from "../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";
import { textData } from "../../../../global/test";

export const requestSearchLedger = () => {
  return {
    type: "REQUEST_ADD_LEDGER",
  };
};
export const successSearchLedger = (data) => {
  return {
    type: "SUCCESS_ADD_LEDGER",
    payload: data,
  };
};
export const errorSearchLedger = () => {
  return {
    type: "ERROR_ADD_LEDGER",
  };
};

export const SearchLedger = () => {
  return (dispatch) => {
    dispatch(requestSearchLedger());
    API.get(`/inventory/getInventoryHistory`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successSearchLedger(data1));
      })
      .catch((error) => {
        dispatch(errorSearchLedger());
      });
  };
};

export const requestSearchLedgerByDate = () => {
  return {
    type: "REQUEST_ADD_LEDGER_BY_DATE",
  };
};
export const successSearchLedgerByDate = (data) => {
  return {
    type: "SUCCESS_ADD_LEDGER_BY_DATE",
    payload: data,
  };
};
export const errorSearchLedgerByDate = () => {
  return {
    type: "ERROR_ADD_LEDGER_BY_DATE",
  };
};

export const SearchLedgerByDate = (data) => {
  return (dispatch) => {
    dispatch(requestSearchLedgerByDate());
    API.post(`/inventory/getinventoryhistoryByDate`, data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successSearchLedgerByDate(data1));
      })
      .catch((error) => {
        dispatch(errorSearchLedgerByDate());
      });
  };
};

export const requestLevel5LedgerData = () => {
  return {
    type: "REQUEST_LEVEL5_LEDGER_DATA",
  };
};
export const successLevel5LedgerData = (data) => {
  return {
    type: "SUCCESS_LEVEL5_LEDGER_DATA",
    payload: data,
  };
};
export const errorLevel5LedgerData = () => {
  return {
    type: "ERROR_LEVEL5_LEDGER_DATA",
  };
};

export const getLevel5LedgerData = () => {
  return (dispatch) => {
    dispatch(requestLevel5LedgerData());
    API.get(`/levelFour/get_levelFour`)
      .then((res) => {
        let getData = res.data;
        dispatch(successLevel5LedgerData(getData));
      })
      .catch((error) => {
        dispatch(errorLevel5LedgerData());
      });
  };
};

// export const requestSearchAccountLedger = () => {
//   return {
//     type: "REQUEST_SEARCH_LEDGER",
//   };
// };
// export const successSearchAccountLedger = (data) => {
//   return {
//     type: "SUCCESS_SEARCH_LEDGER",
//     payload: data,
//   };
// };
// export const errorSearchAccountLedger = () => {
//   return {
//     type: "ERROR_SEARCH_LEDGER",
//   };
// };

// export const searchAccountLedger = (data, startDate, endDate) => {
//   return (dispatch) => {
//     dispatch(requestSearchAccountLedger());
//     API.post(`/levelFour/account_ledger_pdf`, data)
//       .then((res) => {
//         let getData = res.data;
//         dispatch(successSearchAccountLedger(getData));
//       })
//       .catch((error) => {
//         dispatch(errorSearchAccountLedger());
//       });
//   };
// };

export const requestSearchAccountLedger = () => {
  return {
    type: "REQUEST_SEARCH_LEDGER",
  };
};
export const successSearchAccountLedger = (data, path) => {
  return {
    type: "SUCCESS_SEARCH_LEDGER",
    payload: data,
    path: path,
  };
};
export const errorSearchAccountLedger = () => {
  return {
    type: "ERROR_SEARCH_LEDGER",
  };
};

export const searchAccountLedger = (data, startDate, endDate, path) => {
  return (dispatch) => {
    dispatch(requestSearchAccountLedger());
    API.post(`/levelFive/get-acc-data-user`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successSearchAccountLedger(getData, path));
      })
      .catch((error) => {
        dispatch(errorSearchAccountLedger());
      });
  };
};
//ledger report
export const requestSearchAccountLedgerReport = () => {
  return {
    type: "REQUEST_SEARCH_LEDGER_REPORT",
  };
};
export const successSearchAccountLedgerReport = (data) => {
  return {
    type: "SUCCESS_SEARCH_LEDGER_REPORT",
    payload: data,
  };
};
export const errorSearchAccountLedgerReport = () => {
  return {
    type: "ERROR_SEARCH_LEDGER_REPORT",
  };
};

export const searchAccountLedgerReport = (data, startDate, endDate,accountName) => {
  return (dispatch) => {
    console.log(data, "data");
    dispatch(requestSearchAccountLedgerReport());
    API.post(`/levelFour/account_ledger_pdf`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "data");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = "Accounts Ledger";
        doc.addFileToVFS('NotoSansArabic.ttf', textData);
        doc.addFont('NotoSansArabic.ttf', 'NotoSansArabic', 'normal');
        doc.setFont('NotoSansArabic');

        const Dates =
          !isEmpty(startDate) || !isEmpty(endDate)
            ? "Form: " + " " + startDate + "  to:  " + endDate
            : "Form: -  To: - ";
        // const accountNo = "Account #:  " + getData.data.key;
        const companyName = `${getData.resImage.wareHouseName}`;
        const companyAddress = `${getData.resImage.wareHouseLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const logo1=logo;
        const accountTitle = "Account Title:  " + accountName
        const TotalCredit = "Total Credit: " + getData.creditSum;
        const TotalDebit = "Total Debit: " + getData.debitSum;
        //  const Rarray = getData.data.slice(-1);
        // const TotalBalance = "Closing Balance: " + Rarray[0].accBalance.toLocaleString();

        const headers = [
          [
            "DATE",
            "VOUCHER#",
            "DESCRIPTION",
            "BILL NO",
            "OPENING BALANCE",
            "DEBIT",
            "CREDIT",
            "CLOSEING BALANCE",
            "DR/CR",
          ],
        ];
        const data = getData.data.map((x) => [
          x.voucherDate,
          x.vocManualNumber,
          x.voucherDescription,
          x.billNumber,
          Number(x.openingBalance).toLocaleString(),
          isNull(x.debit) ? 0 : x.debit,
          isNull(x.credit) ? 0 :x.credit,
          x.closingBalance,
          x.accType == 2 ? "Debit" : x.accType == 1 ? "Credit" : x.openingBalanceSign,
        ]);

        let content = {
          startY: 230,
          startX: 10,
          head: headers,
          body: data,
          styles: {
            font: 'NotoSansArabic',
            fontStyle: 'normal',
            // fontWeight:'bold',
            fontSize:9,
          }
        };
        doc.addImage(logo1, 'PNG', 40, 15, 100, 100);
        doc.setFontSize(16);

        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
   
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        doc.text(accountTitle, marginLeft, 160, 0, 20);
        doc.setFontSize(18);
 
        doc.text(title, 250, 140);
        doc.setFontSize(13);

        doc.text(TotalCredit, marginLeft, 180);
        doc.text(TotalDebit, marginLeft, 200);
        //  doc.text(TotalBalance, marginLeft, 225);
        doc.autoTable(content);
        doc.setProperties({
          title: `Account Ledger Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}`,
        });
        doc.output("dataurlnewwindow");
        //  doc.save(`Account Ledger Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`)
        dispatch(successSearchAccountLedgerReport(getData));
      })
      .catch((error) => {
        dispatch(errorSearchAccountLedgerReport());
        console.log(error, "eeeeeeeeeeee");
      });
  };
};
//ledger matrial table
export const requestSearchAccountLedgerTable = () => {
    return {
      type: "REQUEST_SEARCH_LEDGER_TABLE",
    };
  };
  export const successSearchAccountLedgerTable = (data) => {
    return {
      type: "SUCCESS_SEARCH_LEDGER_TABLE",
      payload: data,
    };
  };
  export const errorSearchAccountLedgerTable = () => {
    return {
      type: "ERROR_SEARCH_LEDGER_TABLE",
    };
  };
  
  export const searchAccountLedgerTable = (data, startDate, endDate,accountName) => {
    return (dispatch) => {
      console.log(data, "data");
      dispatch(requestSearchAccountLedgerTable());
      API.post(`/levelFour/account_ledger`, data)
        .then((res) => {
          let getData = res.data;
          console.log(getData, "data11");
          dispatch(successSearchAccountLedgerTable(getData));
        })
        .catch((error) => {
          dispatch(errorSearchAccountLedgerTable());
          console.log(error, "eeeeeeeeeeee");
        });
    };
  };
